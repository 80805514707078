import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import Grid from '@mui/material/Grid';
import RuleIcon from '@mui/icons-material/Rule';
import LockIcon from '@mui/icons-material/Lock';
import CalculateIcon from '@mui/icons-material/Calculate';
import React, { useCallback, useEffect, useState } from 'react';
import instance from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';
import { useParams } from 'react-router-dom';
import ClosingDialog from './ClosingDialog';
import { ICheckResponse, ICheckStatus } from './types';
import ConfirmationDialog from './ConfirmationDialog';
import useOpen from '../../../../utils/useOpen';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import InformDialog from './InformDialog';
import Tooltip from '@mui/material/Tooltip';
import RecalculationDialog from './RecalculationDialog';

const Button = (props: LoadingButtonProps) => (
    <Grid item>
        <LoadingButton variant="contained" loadingPosition="start" {...props} />
    </Grid>
);

export default function ClosingButtons({
    closed,
    endDateLater,
    setPageLoading,
    lastClosingProcesssLog,
}: {
    closed?: string;
    endDateLater?: boolean;
    setPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
    lastClosingProcesssLog: null | number;
}) {
    const { semesterId } = useParams();
    const openState = useOpen();
    const { open } = openState;
    const [loading, setLoading] = useState(false);
    const [checkResponse, setCheckResponse] = useState<ICheckResponse | null>(null);
    const [isClosing, setIsClosing] = useState(false);
    const [recalcOpen, setRecalcOpen] = useState(false);
    const { user } = useSelector((state: RootState) => state.auth);

    const handleClosingCheck = useCallback(
        (addedCheckResponse: ICheckResponse, timeout?: number) => {
            if (!addedCheckResponse) return;
            setTimeout(async () => {
                const { data } = await instance.get<ICheckResponse>(
                    endpoints.masterData.semesters.checkClosingStatus(semesterId ?? '0', '' + addedCheckResponse?.log),
                );
                if (['init', 'processing'].includes(data.status)) {
                    setCheckResponse(data);
                    handleClosingCheck(data);
                } else if (
                    ['check_error_course', 'check_error_student', 'close_error_student', 'close_error_course'].includes(
                        data.status,
                    )
                ) {
                    setLoading(false);
                    setCheckResponse({
                        log: data.log,
                        status: data.status.replace('close', 'check') as ICheckStatus,
                    });
                } else {
                    setLoading(false);
                    setCheckResponse(data);
                }
            }, timeout ?? 2000);
        },
        [setCheckResponse, setLoading, semesterId],
    );

    useEffect(() => {
        if (lastClosingProcesssLog && semesterId) {
            handleClosingCheck(
                {
                    log: lastClosingProcesssLog,
                    status: 'init',
                },
                0,
            );
        }
    }, [lastClosingProcesssLog, handleClosingCheck, semesterId]);

    if (!semesterId) return null;

    const handleCheck = async (isClosing = false) => {
        setIsClosing(isClosing);
        setLoading(true);
        try {
            const response = await instance.post<ICheckResponse>(
                endpoints.masterData.semesters[isClosing ? 'close' : 'check'](semesterId),
            );
            setCheckResponse(response.data);
            handleClosingCheck(response.data);
        } catch {
            setLoading(false);
        }
    };

    let disabledTooltip = '';
    if (endDateLater) {
        disabledTooltip = 'A szemeszter vége előtt a szemeszter zárása nem lehetséges!';
    } else if (!!closed && !user.admin) {
        disabledTooltip = 'Zárt szemeszter zárása nem lehetséges!';
    }

    return (
        <Grid item container justifyContent="flex-end" xs={12} spacing={2}>
            <Tooltip title={disabledTooltip}>
                <Grid item>
                    <Button
                        onClick={() => setRecalcOpen(true)}
                        startIcon={<CalculateIcon />}
                        color="info"
                        loading={loading}
                        disabled={!!endDateLater}
                        sx={{ backgroundColor: 'rgb(243, 202, 18)' }}
                    >
                        Teljesítések újraszámolása
                    </Button>
                </Grid>
            </Tooltip>
            <Tooltip title={disabledTooltip}>
                <Grid item>
                    <Button
                        onClick={() => handleCheck()}
                        startIcon={<RuleIcon />}
                        color="warning"
                        loading={loading}
                        disabled={!!endDateLater}
                    >
                        Teljesítések ellenőrzése
                    </Button>
                </Grid>
            </Tooltip>
            <Tooltip title={disabledTooltip}>
                <Grid item>
                    <Button
                        color="error"
                        startIcon={<LockIcon />}
                        loading={loading}
                        onClick={open}
                        disabled={!!endDateLater || !(!closed || user.admin)}
                    >
                        Szemeszter zárása
                    </Button>
                </Grid>
            </Tooltip>
            <InformDialog checkResponse={checkResponse} isClosing={isClosing} setPageLoading={setPageLoading} />
            <ConfirmationDialog onClick={() => handleCheck(true)} {...openState} />
            <ClosingDialog checkResponse={checkResponse} setPageLoading={setPageLoading} />
            <RecalculationDialog open={recalcOpen} setOpen={setRecalcOpen} semesterId={semesterId} />
        </Grid>
    );
}
