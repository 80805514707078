import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px', marginBottom: '12px' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <Box sx={{ minWidth: 100 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

export default function LabeledLinearProgress({ progress }: { progress: number }) {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
}
