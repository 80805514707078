import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Dispatch, useEffect, useState } from 'react';
import LabeledLinearProgress from '../../../../components/LabeledLinearProgress';
import { endpoints } from '../../../../utils/endPoints';
import { ICheckResponse } from './types';
import instance from '../../../../api';
import DialogTitle from '@mui/material/DialogTitle';

export default function RecalculationDialog({
    open,
    setOpen,
    semesterId,
}: {
    open: boolean;
    setOpen: Dispatch<React.SetStateAction<boolean>>;
    semesterId: string;
}) {
    const [checkResponse, setCheckResponse] = useState<ICheckResponse | null>(null);
    const [warningAccepted, setWarningAccepted] = useState(false);
    const close = () => {
        setOpen(false);
        setWarningAccepted(false);
    };
    const accept = () => {
        setWarningAccepted(true);
    };

    useEffect(() => {
        if (open && warningAccepted) {
            instance.post(endpoints.masterData.semesters.recalc(semesterId)).then((data) => {
                setCheckResponse(data.data);
            });
        }
    }, [open, warningAccepted, semesterId]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (open && warningAccepted && checkResponse?.status !== 'completed') {
            intervalId = setInterval(async () => {
                const response = await instance.get<ICheckResponse>(
                    endpoints.masterData.semesters.checkRecalcStatus(semesterId ?? '0', '' + checkResponse?.log),
                );
                setCheckResponse(response.data);

                if (response.data.status === 'completed') {
                    clearInterval(intervalId);
                }
            }, 5000);
        }

        return () => clearInterval(intervalId);
    }, [open, warningAccepted, checkResponse?.log, checkResponse?.status, semesterId]);

    return (
        <>
            <Dialog open={open && !warningAccepted} fullWidth>
                <DialogTitle>Megerősítés</DialogTitle>
                <DialogContent style={{ paddingTop: '12px', paddingBottom: 0 }}>
                    A teljesítések újraszámolása az összes hallgatóra vonatkozik a kiválasztott szemeszterben. Biztosan
                    folytatod?
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button onClick={accept} variant="contained">
                                Igen
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={close} variant="contained" color="error">
                                Nem
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={open && warningAccepted} fullWidth>
                <DialogContent style={{ paddingTop: '12px', paddingBottom: 0 }}>
                    {checkResponse?.status == 'completed' ? (
                        <Alert severity="success" variant="filled" style={{ color: 'white' }}>
                            Sikeresen lefutott a teljesítések újraszámolása!
                        </Alert>
                    ) : (
                        <Alert severity="warning" variant="filled">
                            Teljesítések újraszámítása folyamatban, kérlek, várj…
                        </Alert>
                    )}
                    <LabeledLinearProgress progress={checkResponse?.process ?? 0} />
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={close}
                                variant="contained"
                                disabled={checkResponse?.status == 'init' || checkResponse?.status == 'processing'}
                            >
                                Bezárás
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}
