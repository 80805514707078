import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';

interface PaymentGuideDialogProps {
    open: boolean;
    handleClose: () => void;
}

interface Version {
    versionId: number;
    versionText: string;
}

const PaymentGuideDialog = ({ open, handleClose }: PaymentGuideDialogProps) => {
    const { data, loading } = useFetch<Version>(endpoints.students.finances.paymentGuide, '');

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>Befizetéshez segédlet</DialogTitle>
            <DialogContent sx={{ marginTop: '1rem' }}>
                {loading || !data ? (
                    <Typography variant="body2">Betöltés...</Typography>
                ) : (
                    <DialogContentText dangerouslySetInnerHTML={{ __html: data?.versionText }} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Vissza
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentGuideDialog;
